// Generic imports
import React, { useState, useEffect } from "react"

// Other components imports
import Layout from "components/layout"
import Login from "components/Account/Login"
import Register from "components/Account/Register"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports
import { getParameterByName } from "services/utils"

const RegisterPage = () => {
  const [showModalLogin, setShowModalLogin] = useState(false)
  const [showModalRegister, setShowModalRegister] = useState(true)
  const [redirectTo, setRedirectTo] = useState("")

  useEffect(() => {
    setRedirectTo(getParameterByName("redirect"))
  }, [])

  return (
    <Layout>
      <SEO title="Register" />
      <Login
        showModalLogin={showModalLogin}
        setShowModalLogin={setShowModalLogin}
        setShowModalRegister={setShowModalRegister}
        redirectTo={redirectTo}
      />
      <Register
        showModalRegister={showModalRegister}
        setShowModalLogin={setShowModalLogin}
        setShowModalRegister={setShowModalRegister}
        redirectTo={redirectTo}
      />
    </Layout>
  )
}

export default RegisterPage
